footer {
  /* min-height: 0vh; */
  background: #ffcc34;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  color: #ffffff;
  padding-bottom: 1%;
}

footer p {
  text-align: center;
  font-size: 12px;
  margin: 10px 0px 0px 0px;
  padding-bottom: 10px;

  font-family: "Google Sans", "Open Sans";
}

.firstLine {
  align-items: center;
  text-align: center;
}

.firstLine img {
  height: 51.54px;
  /* height: 4vw; */
  margin: 20px 0px 15px 0px;
}

.socMedIcons {
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  /* flex-wrap: wrap; */
  margin-bottom: 15px;
}

/* .socMedIcons > * {
  flex: 1 1 10em;
} */


.socMedIconsIndiv {
  height: 28px;
  /* height: 4vh; */
  width: auto;
  cursor: pointer;
  color: #ffffff;
}

.margin-spacing {
  margin-right: 28px;
}

.DSCLogo {
  height: 22.78px;
  padding: 0px;
  margin: 0px;
}

.BsLinkedin {
  border-radius: 50%;
}

.lastLine {
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.lastLine img {
  height: 10px;
  width: 100%;
  margin-bottom: 0px;
  position: absolute;
  bottom: 0;
}

@media screen and (max-width: 700px) {
  footer {
    padding: 4% 0 2% 0;
  }

  .firstLine img {
    height: 24.54px;
    /* height: 4vw; */
    margin: 20px 0px 15px 0px;
  }

  .socMedIconsIndiv {
    height: 24px;
    /* height: 4vh; */
    width: auto;
    cursor: pointer;
    color: #ffffff;
  }

  .socMedIcons {
    align-items: center;
    justify-content: center;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
  }

  .DSCLogo {
    height: 20.78px;
    padding: 0px;
    margin: 0px;
  }

  .media-screen-up {
    display: flex;
    margin-bottom: 10px;
  }

  .media-screen-down {
    display: flex;
    /* margin-right: 20px; */
  }

  .github-icon {
    padding-left: 4px;
  }

  .discord-icon {
    padding-left: 9px;
  }

  .yt-icon {
    padding-right: 6px;
  }

  .ig-icon {
    padding-right: 2px;
  }
}

@media screen and (max-width: 500px) {

  .footer p {
    font-size: 10px;
  }
  .firstLine img {
    height: 24.54px;
    /* height: 4vw; */
    margin: 20px 0px 15px 0px;
  }

  .socMedIconsIndiv {
    height: 20px;
    /* height: 4vh; */
    width: auto;
    cursor: pointer;
    color: #ffffff;
  }

  .socMedIcons {
    align-items: center;
    justify-content: center;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
  }

  .DSCLogo {
    height: 16.78px;
    padding: 0px;
    margin: 0px;
  }

  .media-screen-up {
    display: flex;
    margin-bottom: 10px;
  }

  .media-screen-down {
    display: flex;
    /* margin-right: 20px; */
  }

  .github-icon {
    padding-left: 0px;
  }

  .discord-icon {
    padding-left: 9px;
  }

  .yt-icon {
    padding-right: 17px;
  }

  .ig-icon {
    padding-right: 5px;
  }
}

@media (max-width: 414px) {
  footer {
    padding: 4% 0 4% 0;
  }
}
