.schedule {
  /* positioning */
  position: relative;

  /* box model */
  /* height: 1200px;
  overflow: hidden; */

  /* typography */
  font-family: "Google Sans", "Open Sans";

  /* visual */
  /* background: url("../../../../assets/images/png/colored-arrows.png"); */
  background-repeat: repeat-y; 
  background-size: clamp(350px, 96%, 1400px) auto;
  background-position: center;
}

.schedule .background-img {
  /* positioning */
  left: -10%;
  margin: 20px auto auto auto;
  position: absolute;
  right: -10%;
  z-index: 0;

  /* box model */
  width: 1440px;
  max-width: 120%;
}

.schedule .main-content {
  left: 0;
  margin: auto;
  right: 0;
  z-index: 1;
  padding: 6% 0 10% 0;
}

.schedule h2 {
  /* box model */
  width: 1200px;
  max-width: 80%;
  margin: 0px auto 0 auto;

  /* typography */
  font-size: 56px;
  font-weight: 700;
  text-align: center;
}

.schedule p {
  font-size: 24px;
  margin: 0 auto;
  max-width: 80%;
  text-align: center;
  width: 500px;
}

.schedule .day-selector-container {
  /* positioning */
  align-content: space-evenly;
  align-items: center;
  justify-content: space-evenly;
  justify-items: center;
  margin: 80px auto 0 auto;

  /* box model */
  border-bottom: 1px solid #c4c4c4;
  display: grid;
  grid-template-columns: repeat(5, auto);
  max-width: 80%;
  width: 1320px;

  /* misc */
  cursor: pointer;
}

.schedule .day-option {
  /* positioning */
  margin: 0 auto;

  /* box model */
  display: grid;
  height: 120px;
  width: 100%;
  grid-template-rows: 20% 70% 10%;

  /* typography */
  text-align: center;
}

.schedule .day-option .day-number {
  font-size: 28px;
}

.schedule .day-option .month-n-date {
  font-size: 40px;
  font-weight: 500;
  line-height: 2em;
  text-align: center;
}

.schedule .day-option .highlight-container {
  background: linear-gradient(268.35deg, #f44336 19.42%, #ffd54f 83.57%);
  border-radius: 24px;
  transition: 300ms ease-in-out;
  width: 0;
}

.schedule .day-option .active {
  transition: 300ms ease-in-out;
  width: 100%;
}

.schedule .speakers-of-the-day {
  /* positioning */
  margin: 100px auto 0 auto;
  justify-content: space-evenly;
  justify-items: center;
  align-content: space-evenly;
  align-items: center;

  /* box model */
  width: 1200px;
  max-width: 80%;
  display: grid;
  grid-template-columns: repeat(3, auto);
}

.schedule .speaker {
  height: 440px;
  width: 224px;
  /* background:rgb(145, 255, 216); */
  margin-bottom: 15%;
}

.schedule .speaker .photo-container {
  background: rgb(167, 167, 167);
  height: 224px;
}

.schedule .speaker .photo-container img {
  width: 100%;
  z-index: 2;
}

.schedule .speaker .talk-details-container {
  position: relative;
  height: 50%;
}

.schedule .speaker .tag {
  /* positioning */
  margin: 12px 0;
  justify-content: center;
  align-items: center;

  /* box model */
  height: 2.2em;
  width: 4em;
  border-radius: 8px;
  display: inline-flex;

  /* typography */
  color: #ea4335;
  text-align: center;
  font-weight: 600;

  /* visual */
  background: #ededed;
}


.schedule .speaker .Ready {
  background: #FFEBEE;
  color: #EA4335;
}

.schedule .speaker .Set {
  background: #faf0d1;
  color: #ffbf00;
}

.schedule .speaker .Grow {
  background: rgba(102, 187, 106, 0.15);
  color: rgb(102, 187, 106);
}

.schedule .speaker .Talks {
  background: rgba(73, 138, 244, 0.15);
  color: rgb(73, 138, 244);
}

.schedule .speaker .speaker-name {
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
  margin: 0;
}

.schedule .speaker .organization {
  color: #676c72;
  font-size: 14px;
  margin: 8px 0;
  text-align: left;
}

.schedule .speaker .talk-title {
  color: #434343;
  font-size: 15px;
}

.schedule .speaker .talk-time {
  bottom: 0;
  font-size: 15px;
  font-weight: 500;
  margin-top: 14px;
  position: absolute;
}

.schedule .speaker .talk-time svg {
  font-size: 20px;
  margin-right: 4px;
  vertical-align: top;
}





/* === 1024px === */

@media(max-width: 1024px) {
  .schedule h2 {
    font-size: 44px;
  }
  
  .schedule p {
    font-size: 20px;
  }

  .schedule .day-option {
    height: 88px;
  }

  .schedule .day-option .day-number {
    font-size: 20px;
  }
  
  .schedule .day-option .month-n-date {
    font-size: 32px;
  }
  
  .schedule .speakers-of-the-day {
    max-width: 90%;
  }
  
  .schedule .speaker .tag {
    margin-top: 16px;
  }
}





/* === Tablet 834px === */

@media(max-width: 834px) {
  .schedule h2 {
    font-size: 44px;
  }
  
  .schedule .day-option .day-number {
    font-size: 20px;
  }
  
  .schedule .day-option .month-n-date {
    font-size: 32px;
  }
  
  .schedule .speakers-of-the-day {
    max-width: 90%;
  }
  
  .schedule .speaker {
    height: 460px;
    width: 178px;
  }
  
  .schedule .speaker .photo-container {
    height: 178px;
  }
  
  .schedule .speaker .tag {
    font-size: 14px;
  }
  
  .schedule .speaker .speaker-name {
    font-size: 20px;
  }
}









/* === Tablet 680px === */

@media(max-width: 680px) {
  .schedule .main-content {
    padding-top: 10%;
  }

  .schedule h2 {
    font-size: 36px;
  }
  
  .schedule p {
    font-size: 18px;
  }
  
  .schedule .day-option {
    height: 64px;
  }
  
  .schedule .day-option .day-number {
    font-size: 16px;
  }
  
  .schedule .day-option .month-n-date {
    font-size: 24px;
  }
  
  .schedule .speakers-of-the-day {
    margin-top: 60px;
  }
  
  .schedule .speaker {
    width: 150px;
    height: 460px;
  }
  
  .schedule .speaker .photo-container {
    height: 150px;
  }
  
  .schedule .speaker .tag {
    font-size: 15px;
    height: 28px;
  }
  
  .schedule .speaker .speaker-name {
    font-size: 18px;
  }
  
  .schedule .speaker .talk-title {
    font-size: 14px;
  }
  
  .schedule .speaker .talk-time {
    font-size: 14px;
  }
  
  .schedule .speaker .talk-time svg {
    font-size: 16px;
  }  
}






/* === Large mobile 540px === */

@media(max-width: 540px) {
  .schedule h2 {
    font-size: 32px;
  }
  
  .schedule p {
    font-size: 16px;
  }
  
  .schedule .day-option {
    height: 30px;
    grid-template-rows: 60% 40%;
  }
  
  .schedule .day-option .day-number {
    font-size: 16px;
  }
  
  .schedule .day-option .month-n-date {
    display: none;
  }
  
  .schedule .day-option .highlight-container {
    height: 5px;
    margin-top: 3px;
  }
  
  .schedule .speakers-of-the-day {
    display: flex;
    flex-wrap: wrap;
  }
  
  .schedule .speaker {
    width: 150px;
    height: 460px;
    margin-bottom: 0;
  }
  
  .schedule .speaker .photo-container {
    height: 150px;
  }
  
  .schedule .speaker .tag {
    font-size: 14px;
    height: 28px;
  }
  
  .schedule .speaker .speaker-name {
    font-size: 18px;
  }
   
  .schedule .speaker .talk-title {
    font-size: 14px;
  }
  
  .schedule .speaker .talk-time {
    font-size: 14px;
  }
  
  .schedule .speaker .talk-time svg {
    font-size: 16px;
  }  
}




/* === Mobile 414px === */

@media(max-width: 414px) {
  .schedule {
    background-size: clamp(380px, 98%, 1400px) auto;
  }

  .schedule h2 {
    max-width: 90%;
    font-size: 28px;
  }
  
  .schedule p {
    max-width: 90%;
    font-size: 14px;
  }
  
  .schedule .day-option {
    height: 30px;
    grid-template-rows: 60% 40%;
  }
  
  .schedule .day-option .day-number {
    font-size: 16px;
  }
  
  .schedule .day-option .month-n-date {
    display: none;
  }
  
  .schedule .speakers-of-the-day {
    margin-top: 60px;
    max-width: 85%;
  }
  
  .schedule .speaker {
    height: 440px;
    width: 224px;
    /* background:rgb(145, 255, 216); */

    /* Prev Changes */
    height: 480px;
    width: 178px;

    /* prev changes */
    width: 150px;
    height: 460px;

    /* changes */
    width: 140px;
    height: 460px;
  }
  
  .schedule .speaker .photo-container {
    height: 140px;
  }
  
  .schedule .speaker .tag {
    font-size: 12px;
    height: 24px;
  }
  
  .schedule .speaker .speaker-name {
    font-size: 18px;
  }
  
  .schedule .speaker .talk-title {
    font-size: 14px;
  }
  
  .schedule .speaker .talk-time {
    font-size: 14px;
  }
  
  .schedule .speaker .talk-time svg {
    font-size: 16px;
  }  
}




/* === Small Mobile 414px === */

@media(max-width: 310px) {
  .schedule {
    background-size: clamp(240px, 112%, 1400px) auto;
  }
}

