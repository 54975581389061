.in-event-hero {
  /*positioning*/
  position: relative;

  /* box model*/
  height: 95vh;
  max-height: 1100px;
  overflow: hidden;
  padding-left: 10%;
}

.in-event-hero .colored-bars-2 {
  /*positioning*/
  position: absolute;
  right: 0;
  bottom: -42%;
  justify-items: bottom;
  z-index: 0;

  /* box model */
  height: 140%;
  max-height: 1360px;

  /* animation */
  transition: 200ms ease-in-out;
}

.in-event-hero .colored-bars-2 img {
   /*positioning*/
  bottom: 0;
  height: 100%;

  position: relative;

  /* animation */
  transition: 200ms ease-in-out;
}

.in-event-hero .event-details {
  /* positioning */
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  z-index: 1;

  /* box model */
  width: 700px;
  
  /* typography */
  font-family: "Google Sans", "Open Sans";

  /* animation */
  transition: 200ms ease-in-out;
}

.in-event-hero .event-name {
  /* box model */
  width: 95%;

  /* typography */
  font-size: 124px;
  font-weight: 600;
  line-height: 1em;

  /* animation */
  transition: 300ms ease-in-out;
}

.in-event-hero .event-name :nth-child(1) { color: #f44336; }
.in-event-hero .event-name :nth-child(2) { color: #fbbf0e; }
.in-event-hero .event-name :nth-child(3) { color: #66bb6a; }


.in-event-hero .date-of-event {
  /* positioning */
  margin: 24px 0 0 4px;

  /* box model */
  width: 95%;

  /* typography */
  font-size: 36px;
  font-weight: 400;

  /* animation */
  transition: 200ms ease-in-out;
}

.in-event-hero .tagline {
  font-size: 24px;
  margin: 16px 0 0 4px;
  transition: 200ms ease-in-out;
  width: 95%;
}

.in-event-hero .btn-container {
  /* positioning */
  margin-top: 36px;
  align-items: center;

  /* box model */
  display: flex;
  flex-wrap: wrap;

  /* animation */
  transition: 200ms ease-in-out;
}

.in-event-hero .event-details a {
  /* positioning */
  margin: 0 auto;

  /* box model */
  display: flex;
  flex-direction: column;
  height: 64px;
  justify-content: center;
  width: 204px;

  /* typography */
  text-align: center;
  font-size: 24px;
  text-decoration: none;
  color: black;

  /* animation */
  transition: 200ms ease-in-out;
}

.in-event-hero .event-details .register-btn {
  background-color: #FFFF;
  border-radius: 28px;
  border: 2px solid #ffd772;
  box-sizing: border-box;
  margin-right: 0;
}

.in-event-hero .event-details .learn-more-btn {
  margin-left: 40px;
  text-align: left;
}





/* ======== HD Screens ======== */
/* Width: 1440px  */

@media (max-width: 1440px) {
  
  .in-event-hero .event-details {
    width: 680px;
  }

  .in-event-hero .event-name {
    font-size: 110px;
    width: 95%;
  }

  .in-event-hero .date-of-event {
    font-size: 30px;
    margin: 24px 0 0 4px;
  }

  .in-event-hero .tagline {
    font-size: 20px;
    margin: 16px 0 0 4px;
  }


  .in-event-hero .event-details a {
    /* box model*/
    width: 180px;
    height: 56px;

    /* typography */
    font-size: 20px;
    text-decoration: none;
    color: black;
  }
}





/* Screen Width of iMac */
/* ==== Width: 1280px  Height(Best): 720px ==== */

@media (max-width: 1280px) {
  .in-event-hero .colored-bars-2 {
    max-height: 1240px;
  }

  .in-event-hero .event-details {
    width: 560px;
  }

  .in-event-hero .event-name {
    font-size: 100px;
  }

  .in-event-hero .date-of-event {
    font-size: 28px;
  }

  .in-event-hero .tagline {
    font-size: 18px;
  }

  .in-event-hero .event-details a {
    /* Changes */
    font-size: 18px;
    height: 48px;
    width: 160px;
  }
}



/* ==== Medium screen width ==== */
/* ==== Width: 1024px ===== */

@media (max-width: 1024px) {
  .in-event-hero {
    height: 94vh;
    max-height: 900px;
    padding: 0;

    /* background: url("../../../../assets/images/png/colored-arrows(small).png"); */
    background-repeat: no-repeat; 
    background-size: clamp(500px, 90%, 90%) auto;
    background-position: top;
  }

  .in-event-hero .confetti {
    position: absolute;
    right: 0;
    left: 0;
    margin: 0 auto;
    width: 90%;
  }

  

  .in-event-hero .colored-bars-2 {
    /* positioning*/
    left: 0;
    right: 0;
    margin: 0 auto;

    /* box model */
    height: auto;
    max-height: 1180px;
    width: 480px;
    
    /* typography */
    text-align: center;
  }

  .in-event-hero .colored-bars-2 img {
    width: 90%;
  }

  .in-event-hero .event-details {
    /* positioning */
    left: 0;
    right: 0;
    top: 30%;
    margin: auto;

    /* box model */
    width: 100%;
    max-width: 100%;

    /* typography */
    text-align: center;
  }

  .in-event-hero .event-name {
    font-size: 90px;
    margin: 0;
    text-align: center;
    width: 100%;
  }


  .in-event-hero .date-of-event {
    font-size: 28px;
    margin-top: 24px;
  }

  .in-event-hero .tagline {
    font-size: 18px;
    margin-top: 16px;
  }

  .in-event-hero .btn-container {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-top: 36px;
  }

  .in-event-hero .event-details a {
    /* positioning */
    justify-content: center;
    margin: 0 auto;

    /* box model */
    height: 48px;
    width: 160px;

    /* typography */
    text-align: center;
    font-size: 18px;
  }

  .in-event-hero .event-details .register-btn {
    margin-right: 16px;
  }

  .in-event-hero .event-details .learn-more-btn {
    margin-left: 16px;
  }

  @media (min-height: 595px) and (max-height: 620px) {
    .in-event-hero .colored-bars-2 {
      /* height: 600px; */
      width: 360px;
    }
  
    .in-event-hero .event-details {
      width: 480px;
    }
  
    .in-event-hero .event-name {
      font-size: 56px;
    }
  
    .in-event-hero .date-of-event {
      font-size: 20px;
    }
  
    .in-event-hero .tagline {
      font-size: 14px;
    }
  
    .in-event-hero .event-details a {
      /* Changes */
      font-size: 18px;
      height: 48px;
      width: 160px;
    }
  
    .in-event-hero .event-details a {
      font-size: 16px;
      height: 40px;
      width: 140px;
    }
  
    .in-event-hero .event-details .register-btn {
      margin-right: 16px;
    }
  
    .in-event-hero .event-details .learn-more-btn {
      margin-left: 16px;
    }
  }
}







/* ===== Tablets ====== */
/* Width: 834px  */
@media (max-width: 834px) {
  .in-event-hero {
    max-height: 720px;
  }

  .in-event-hero .colored-bars-2 {
    left: -120%;
    right: -120%;
    height: auto;
  }

  .in-event-hero .colored-bars-2 img {
    width: 90%;
  }

  .in-event-hero .event-details {
    /* positioning */
    left: 0;
    right: 0;
    top: 30%;
    margin: auto;

    /* box model */
    max-width: 100%;
    width: 100%;

    /* typography */
    text-align: center;
  }

  .in-event-hero .event-name {
    font-size: 72px;
  }

  .in-event-hero .date-of-event {
    font-size: 24px;
    margin: 16px auto auto auto;
  }

  .in-event-hero .tagline {
    font-size: 16px;
    margin-top: 8px;
  }

  .in-event-hero .event-details a {
    font-size: 16px;
    height: 40px;
    width: 140px;
  }

  .in-event-hero .event-details .register-btn {
    margin-right: 16px;
  }

  .in-event-hero .event-details .learn-more-btn {
    margin-left: 16px;
  }
}





/* === Small Tablets ==== */
/* ===== Width: 680px ==== */

@media (max-width: 680px) {
  .in-event-hero {
    height: 94vh;
    padding: 0;
  }

  .in-event-hero .colored-bars-2 {
    width: 400px;
    bottom: -32%;
  }

  .in-event-hero .colored-bars-2 img {
    width: 90%;
  }

  .in-event-hero .event-details {
    max-width: 90%;
  }

  .in-event-hero .event-name {
    font-size: 60px;
  }

  .in-event-hero .date-of-event {
    font-size: 20px;
  }

  .in-event-hero .tagline {
    font-size: 14px;
  }

  .in-event-hero .btn-container {
    margin-top: 24px;
  }

  .in-event-hero .event-details a {
    font-size: 16px;
    height: 40px;
    width: 140px;
  }

  .in-event-hero .event-details .register-btn {
    margin-right: 16px;
  }

  .in-event-hero .event-details .learn-more-btn {
    margin-left: 20px;
  }
}





/* ====== Large Mobiles ====== */
/* === Width 540px ==== */

@media (max-width: 540px) {

  .in-event-hero .confetti {
    top: 14%;
  }

  .in-event-hero .colored-bars-2 img {
    width: 90%;
  }

  .in-event-hero .event-name {
    font-size: 48px;
  }

  .in-event-hero .date-of-event {
    font-size: 18px;
  }

  .in-event-hero .tagline {
    font-size: 13px;
    margin-top: 4px;
  }

  .in-event-hero .btn-container {
    margin-top: 24px;
  }

  .in-event-hero .event-details a {
    font-size: 14px;
    height: 36px;
    width: 130px;
  }

  .in-event-hero .event-details .register-btn {
    margin-right: 16px;
  }

  .in-event-hero .event-details .learn-more-btn {
    margin-left: 20px;
  }
}





/* ====== Mobile Phones ====== */
/* === Width 414px ==== */

@media (max-width: 414px) {
  .in-event-hero {
    /* Changes */
    height: 94vh;
    max-height: 680px;
    padding: 0;

    background-repeat: repeat-y; 
  }

  .in-event-hero .confetti {
    top: 12%;
  }

  .in-event-hero .colored-bars-2 {
    height: auto;
    width: 85%;
    bottom: -32%;
    left: -20%;
    right: -20%;
    max-width: 120%;
  }

  .in-event-hero .colored-bars-2 img {
    width: 90%;
  }

  .in-event-hero .event-details {
    top: 30%;
  }

  .in-event-hero .event-name {
    font-size: 36px;
  }


  .in-event-hero .date-of-event {
    font-size: 15px;
  }

  .in-event-hero .tagline {
    font-size: 11px;
  }

  .in-event-hero .btn-container {
    margin-top: 24px;
  }

  .in-event-hero .event-details a {
    font-size: 11px;
  }

  .in-event-hero .event-details .register-btn {
    height: 28px;
    width: 120px;
    margin-right: 16px;
  }

  .in-event-hero .event-details .learn-more-btn {
    width: 100px;
    margin-left: 12px;
  }
}

@media (max-width: 320px) {
  .in-event-hero .event-details {
    top: 34%;
  }

  .in-event-hero .colored-bars-2 {
    width: 240px;
  }

}




/* ==== Galaxy Fold ==== */

@media (max-width: 285px) and (max-height: 655px) {
  .in-event-hero {
    /* Changes */
    height: 94vh;
    max-height: 900px;
    padding: 0;
  }

  .in-event-hero .colored-bars-2 {
    bottom: -20%;
    left: -20%;
    right: -20%;
    max-width: 120%;
  }


  .in-event-hero .event-name {
    font-size: 30px;
  }


  .in-event-hero .date-of-event {
    margin-top: 8px;
    font-size: 13px;
  }

  .in-event-hero .tagline {
    margin-top: 2px;
    font-size: 10px;
  }

  .in-event-hero .btn-container {
    margin-top: 24px;
  }

  .in-event-hero .event-details a {
    font-size: 11px;
  }

  .in-event-hero .event-details .register-btn {
    height: 28px;
    width: 120px;
    margin-right: 16px;

    width: 100px;

    margin-right: 0;
  }

  .in-event-hero .event-details .learn-more-btn {
    width: 100px;
    margin-left: 0;
    text-align: center;
  }
}