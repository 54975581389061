nav {
  z-index: 5;
  position: fixed;
  width: 100%;
  top: 0px;
  height: auto;
  background: #ffffff;
  box-sizing: border-box;
  padding-top: 10px;
  font-family: "Google Sans", "Open Sans";
}

nav p {
  margin: 0;
}

.nav-menu-whole {
  display: flex;
  text-align: center;
  background: white;
  width: 90%;
  margin: 0 auto;
}

.nav-menu {
  list-style: none;
  display: flex;
  width: 100%;
}

.logo img {
  position: relative;
  height: 100%;
  margin-left: 10vw;
  width: 39px;
  height: auto;
}

.first-three-menu {
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 8vw;
}

.nav-item {
  padding-right: 6vw;
  cursor: pointer;
}

.nav-learn-more {
  padding-right: 4vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nav-links {
  text-decoration: none;
  color: #737373;
}

.nav-register-now {
  background: #ffd772;
  border: 2px solid #ffd772;
  box-sizing: border-box;
  border-radius: 25px;
  width: 166px;
  height: 38px;
  left: 158px;
  top: 0px;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nav-links-register {
  text-decoration: none;
  color: #404040;
  align-items: center;
  justify-content: center;
}

.two-last-menu {
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  right: 8vw;
  top: 23.4%;
  position: absolute;
}

.menu-icon {
  display: none;
  margin-top: 10px;
}

/* this removes nav bar mobile; only for global navbar css */
.nav-links-register-mobile {
  display: none;
  margin-top: 10px;
}

.nav-register-now-mobile {
  display: none;
  padding-right: 4vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nav-links-register-mobile {
  display: none;
  text-decoration: none;
  color: #737373;
}

@media screen and (max-width: 1024px) {
  nav {
    z-index: 5;
    position: fixed;
    width: 100%;
    top: 0px;
    height: 100px;
    background: #ffffff;
    box-sizing: border-box;
    padding-top: 20px;

    font-family: "Google Sans", "Open Sans";
  }

  .logo img {
    position: relative;
    height: 100%;
    margin-left: 10vw;
    width: 49px;
    height: auto;
  }

  .NavBarItems {
    position: relative;
    position: fixed;
  }

}


@media screen and (max-width: 1024px) {

  nav {
    z-index: 5;
    position: fixed;
    width: 100%;
    top: 0px;
    height: auto;
    background: #ffffff;
    box-sizing: border-box;
    padding-top: 10px;
  
    font-family: "Google Sans";
    font-style: regular;
    font-weight: 400;
    font-size: 15px;
    src: url(https://fonts.gstatic.com/s/googlesans/v36/4UaErENHsxJlGDuGo1OIlL3L8phULjtH.woff2)
      format("woff2");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }

  .logo img {
    position: relative;
    height: 100%;
    margin-left: 10vw;
    width: 39px;
    height: auto;
  }

  .nav-menu-whole {
    display: flex;
    text-align: center;
    align-items: center;
    background: white;
  }
  

  /* --- HAMBURGER STACK CSS --- */

  .NavBarItems {
    position: relative;
    position: fixed;
  }

  .fa-times {
    font-size: 1.5rem;
  }

  .fa-bars {
    font-size: 1.5rem;
  }

  /* Fa plus and fa bar */
  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    margin-right: 40px;
    margin-top: 0px;    
  }
}

@media screen and (max-width: 1024px) {
  nav {
    z-index: 5;
    position: fixed;
    width: 100%;
    top: 0px;
    height: auto;
    background: #ffffff;
    box-sizing: border-box;
    padding-top: 10px;
    font-family: "Google Sans", "Open Sans";
  }

  .logo img {
    position: relative;
    height: 100%;
    margin-left: 10vw;
    width: 39px;
    height: auto;
  }

  .nav-menu-whole {
    display: flex;
    text-align: center;
    align-items: center;
    background: white;
  }

  /* --- HAMBURGER STACK CSS --- */

  .NavBarItems {
    position: relative;
    position: fixed;
  }

  .fa-times {
    font-size: 1.2rem;
  }

  .fa-bars {
    font-size: 1.2rem;
  }

  /* Fa plus and fa bar */
  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    margin-right: 40px;
    margin-top: 0px;    
  }

  /* Fa plus and fa bar */
  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    margin-right: 40px;
    margin-top: 0px;
  }


  
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    position: absolute;
    top: 50px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 0;
  }

  .nav-menu.active {
    background: #ffffff;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    padding-bottom: 20px;
    width:100%;
  }


  /* HOME + EVENTS + ABOUT US */
  .first-three-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 0;
    margin: 0;
  }


  /* NAV + REGISTER */
  .two-last-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin: 0;
    margin-right: 0;
    position: static;
  }

  .nav-item {
    padding: 0;
  }

  /* NAV LINKS */
  .nav-links {
    text-align: center;
    padding: 15px 0;


    width: 100%;
    display: table;
  }

  .nav-links:hover {
    text-decoration: underline;
    color: black;
    transition: 250ms;
  }

  /* REGISTER BUTTON */

  /* removes register button problem only for hamburger stacks */
  .nav-register-now {
    display: none;
  }

  .nav-register-now-mobile {
    width: 15vw;
    background: rgba(0, 0, 0, 0);
    color: black;
    border-radius: 25px;
    margin-top: 10px;
    padding: 0.3rem 0;

  }

  .nav-links-register-mobile {
    text-align: center;
    padding: 0.3rem;
    width: 100%;
    display: table;
    background-color: #ffd772;
    color: black;
    border-radius: 25px;
    font-size: 15px;
    text-decoration: none;

    /* changes */
    margin: 0;
    padding: 0.6rem 0;

  }

  .nav-links-register-mobile:hover {
    text-decoration: underline;
  }

  .nav-learn-more {
    padding: 0;
  }
}


@media screen and (max-width: 800px) {
  nav {
    z-index: 5;
    position: fixed;
    width: 100%;
    top: 0px;
    height: auto;
    background: #ffffff;
    box-sizing: border-box;
    padding-top: 10px;
    font-family: "Google Sans", "Open Sans";
  }

  .logo img {
    position: relative;
    height: 100%;
    margin-left: 10vw;
    width: 39px;
    height: auto;
    margin-top: 10px;
  }

  .nav-menu-whole {
    display: flex;
    text-align: center;
    align-items: center;
    background: white;
  }
  

  /* --- HAMBURGER STACK CSS --- */

  .NavBarItems {
    position: relative;
    position: fixed;
  }

  .nav-menu-whole {
    display: flex;
    text-align: center;
    align-items: center;
    background: white;
  }

  /* --- HAMBURGER STACK CSS --- */

  .NavBarItems {
    position: relative;
    position: fixed;
    padding: 8px 0;
  }

  .fa-times {
    font-size: 1.2rem;
  }

  .fa-bars {
    font-size: 1.2rem;

  }

  /* Fa plus and fa bar */
  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 50%);
    font-size: 1.8rem;
    cursor: pointer;
    margin-right: 40px;
    margin-top: 0px;
  }

  
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    position: absolute;
    top: 50px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 0;
  }

  .nav-menu.active {
    background: #ffffff;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    padding-bottom: 20px;
  }

    /* HOME + EVENTS + ABOUT US */
    .first-three-menu {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: center;
      padding: 0;
      margin: 0;
    }
  
    /* NAV + REGISTER */
    .two-last-menu {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: center;
      margin: 0;
      margin-right: 0;
    }
  
    .nav-item {
      padding: 0;
      width: 200px;
    }
  
    /* NAV LINKS */
    .nav-links {
      text-align: center;
      padding: 15px 0;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      text-decoration: underline;
      color: black;
      transition: 250ms;
    }
  
  
  
  
    /* REGISTER BUTTON */
  
    /* removes register button problem only for hamburger stacks */
    .nav-register-now {
      display: none;
    }
  
    .nav-register-now-mobile {
      width: 15vw;
      /* background-color: #ffd772; */
      background: rgba(0, 0, 0, 0);
      color: black;
      border-radius: 25px;
      margin-top: 10px;
      padding: 0.3rem 0;
      width: 200px;
    }
  
    .nav-links-register-mobile {
      text-align: center;
      padding: 0.3rem;
      width: 100%;
      display: table;
      background-color: #ffd772;
      color: black;
      border-radius: 25px;
      font-size: 15px;
      text-decoration: none;
      
      /* changes */
      margin: 0;
      padding: 0.6rem 0;

      width: 200px;
    }
  
    .nav-links-register-mobile:hover {
      text-decoration: underline;
    }
  
    .nav-learn-more {
      padding: 0;
      
    }
}





@media screen and (max-width: 500px) {
  nav {
    z-index: 5;
    position: fixed;
    width: 100%;
    top: 0px;
    height: auto;
    background: #ffffff;
    box-sizing: border-box;
    padding-top: 10px;

    font-family: "Google Sans", "Open Sans";
  }

  .logo img {
    position: relative;
    height: 100%;
    margin-left: 10vw;
    width: 29px;
    height: auto;
    margin-top: 10px;
  }

  .nav-menu-whole {
    display: flex;
    text-align: center;
    align-items: center;
    background: white;
  }

  /* --- HAMBURGER STACK CSS --- */

  .NavBarItems {
    position: relative;
    position: fixed;
  }

  .fa-times {
    font-size: 1rem;
  }

  .fa-bars {
    font-size: 1rem;
  }

  /* Fa plus and fa bar */
  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    margin-right: 40px;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    position: absolute;
    top: 50px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 0;
  }

   /* HOME + EVENTS + ABOUT US */
   .first-three-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 0;
    margin: 0;
  }

  /* NAV + REGISTER */
  .two-last-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin: 0;
    margin-right: 0;
  }

  .nav-item {
    padding: 0;
    width: 200px;
  }

  /* NAV LINKS */
  .nav-links {
    text-align: center;
    padding: 15px 0;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    text-decoration: underline;
    color: black;
    transition: 250ms;
  }

  /* REGISTER BUTTON */
  /* removes register button problem only for hamburger stacks */
  .nav-register-now {
    display: none;
  }

  .nav-register-now-mobile {
    width: 15vw;
    /* background-color: #ffd772; */
    background: rgba(0, 0, 0, 0);
    color: black;
    border-radius: 25px;
    margin-top: 10px;
    padding: 0.3rem 0;
    width: 160px;
  }

  .nav-links-register-mobile {
    text-align: center;
    padding: 0.3rem;
    width: 100%;
    display: table;
    background-color: #ffd772;
    color: black;
    border-radius: 25px;
    font-size: 12px;
    text-decoration: none;
    
    /* changes */
    margin: 0;
    padding: 0.6rem 0;

    width: 160px;
  }

  .nav-links-register-mobile:hover {
    text-decoration: underline;
  }


  .nav-learn-more {
    padding: 0;
  }
}


@media screen and (max-width: 400px) {
  nav {
    z-index: 5;
    position: fixed;
    width: 100%;
    top: 0px;
    height: auto;
    background: #ffffff;
    box-sizing: border-box;
    padding-top: 10px;

    font-family: "Google Sans", "Open Sans";
  }

  .logo img {
    position: relative;
    height: 100%;
    margin-left: 10vw;
    width: 29px;
    height: auto;
    margin-top: 10px;
  }
  

  .nav-menu-whole {
    display: flex;
    text-align: center;
    align-items: center;
    background: white;
    width: 90%;
    margin: 0 auto;
  }

  /* --- HAMBURGER STACK CSS --- */

  .NavBarItems {
    position: relative;
    position: fixed;
  }

  .fa-times {
    font-size: 1rem;
  }

  .fa-bars {
    font-size: 1rem;
  }

  /* Fa plus and fa bar */
  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 50%);
    font-size: 1.8rem;
    cursor: pointer;
    margin-right: 40px;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    position: absolute;
    top: 50px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 0;
  }

  .nav-menu.active {
    background: #ffffff;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    padding-bottom: 20px;
  }

   /* HOME + EVENTS + ABOUT US */
   .first-three-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 0;
    margin: 0;
  }


  /* NAV + REGISTER */
  .two-last-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin: 0;
    margin-right: 0;
  }

  .nav-item {
    padding: 0;
    width: 90vw;
  }

  /* NAV LINKS */
  .nav-links {
    text-align: center;
    padding: 15px 0;
    width: 120px;
    display: table;
    margin: 0 auto;
  }

  .nav-links:hover {
    text-decoration: underline;
    color: black;
    transition: 250ms;
  }


  /* removes register button problem only for hamburger stacks */
  .nav-register-now {
    display: none;
  }

  .nav-register-now-mobile {
    width: 15vw;
    /* background-color: #ffd772; */
    background: rgba(0, 0, 0, 0);
    color: black;
    border-radius: 25px;
    margin-top: 10px;
    padding: 0.3rem 0;
    width: 120px;
  }

  .nav-links-register-mobile {
    text-align: center;
    padding: 0.3rem;
    width: 100%;
    display: table;
    background-color: #ffd772;
    color: black;
    border-radius: 25px;
    font-size: 12px;
    text-decoration: none;
    /* changes */
    margin: 0;
    padding: 0.6rem 0;

    width: 120px;
  }

  .nav-links-register-mobile:hover {
    text-decoration: underline;
  }

  .nav-learn-more {
    padding: 0;
  }
}