.pre-event-hero {
  /* positioning */
  position: relative;

  /* box model */
  height: 95vh;
  max-height: 1100px;
  overflow: hidden;
  padding-left: 10%;
}

.pre-event-hero .colored-bars {
  bottom: -10%;
  height: 110%;
  justify-items: bottom;
  position: absolute;
  right: -7%;
  z-index: 0;
}

.pre-event-hero .colored-bars img {
  bottom: 0;
  height: 100%;
  position: relative;
}

.pre-event-hero .coming-soon {
  /* positioning */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;

  /* box model */
  width: 600px;

  /* typography*/
  font-family: "Google Sans", "Open Sans";

  /* animation */
  transition: 200ms;
}

.pre-event-hero .coming-soon h3 {
  font-size: 90px;
  font-weight: 400;
  margin: 0 auto;
  width: 95%;
}

.pre-event-hero .coming-soon .count-down {
  margin: 28px auto 0 auto;
}

.pre-event-hero .coming-soon .count-down th {
  font-size: 18px;
  font-weight: 400;
}

.pre-event-hero .coming-soon .count-down .attr {
  max-width: 95px;
  min-width: 95px;
}

.pre-event-hero .coming-soon .count-down tbody {
  font-size: 78px;
  line-height: 100%;
  vertical-align: top;
}

.pre-event-hero .coming-soon .count-down td {
  font-weight: 400;
  padding-top: 0;
  text-align: center;
  vertical-align: top;
}

.pre-event-hero .coming-soon .count-down .value {
  width: 100px;
  min-width: 95px;
  max-width: 95px;
}

.pre-event-hero .coming-soon p {
  align-self: center;
  font-size: 24px;
  font-weight: 400;
  margin: 0 auto;
  width: 95%;
}

.pre-event-hero .coming-soon .btn-container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: 36px;
}

.pre-event-hero .coming-soon a {
  /* positioning */
  justify-content: center;
  margin: 0 auto;

  /* box model */
  display: flex;
  flex-direction: column;
  height: 64px;
  text-align: center;
  width: 204px;

  /* typography */
  color: black;
  font-size: 24px;
  text-decoration: none;
}

.pre-event-hero .coming-soon .register-btn {
  background: #ffff;
  border-radius: 28px;
  border: 2px solid #ffd772;
  box-sizing: border-box;
  margin-right: 0;
  transition: 200ms ease-in-out;
}

.pre-event-hero .coming-soon .register-btn:hover {
  background: #ffd772;
}

.pre-event-hero .coming-soon .learn-more-btn {
  margin-left: 40px;
  text-align: left;
  cursor: pointer;
}


@media (min-width: 2200px) {
  .pre-event-hero {
    /* positioning */
    position: relative;
  
    /* box model */
    height: 95vh;
    max-height: 1100px;
    overflow: hidden;
    padding-left: 10%;

    /* Changes */
    padding-left: 14%;
  }
  
  
.pre-event-hero .colored-bars {
  bottom: -10%;
  height: 110%;
  justify-items: bottom;
  position: absolute;
  right: -7%;
  z-index: 0;

  /* Changes */
  right: 4%;
}

.pre-event-hero .colored-bars img {
  bottom: 0;
  height: 100%;
  position: relative;
}

.pre-event-hero .coming-soon {
  /* positioning */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;

  /* box model */
  width: 600px;

  /* typography*/
  font-family: "Google Sans", "Open Sans";

  /* animation */
  transition: 200ms;
}
}



/* HD 1440px */
@media (max-width: 1440px) {
  .pre-event-hero .coming-soon h3 {
    font-size: 80px;
  }

  .pre-event-hero .coming-soon .count-down th {
    font-size: 15px;
  }

  .pre-event-hero .coming-soon .count-down tbody {
    font-size: 72px;
  }

  .pre-event-hero .coming-soon p {
    font-size: 20px;
  }

  .pre-event-hero .coming-soon a {
    font-size: 20px;
    height: 58px;
    width: 186px;
  }
}



@media (max-width: 1280px) {
  .pre-event-hero .coming-soon {
    width: 560px;
  }

  .pre-event-hero .coming-soon h3 {
    font-size: 64px;
  }

  .pre-event-hero .coming-soon .count-down {
    margin: 28px auto 0 auto;
  }

  .pre-event-hero .coming-soon .count-down th {
    font-size: 14px;
  }

  .pre-event-hero .coming-soon .count-down tbody {
    font-size: 60px;
  }

  .pre-event-hero .coming-soon .count-down .value {
    width: 85px;
    min-width: 80px;
    max-width: 80px;
  }

  .pre-event-hero .coming-soon p {
    font-size: 16px;
  }

  .pre-event-hero .coming-soon a {
    font-size: 16px;
    height: 48px;
    width: 164px;
  }
}



@media (max-width: 1024px) {
  .pre-event-hero {
    max-height: 1180px;
  }

  .pre-event-hero .colored-bars {
    /* positioning*/
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -30%;

    /* box model */
    height: auto;
    max-height: 90%;
    width: 800px;
    max-width: 95%;

    /* typography */
    text-align: center;
  }

  .pre-event-hero .colored-bars img {
    bottom: 0;
    width: 100%;
  }

  .pre-event-hero .coming-soon {
    text-align: center;
    top: 30%;
    right: 0;
    left: 0;
    margin: 0 auto;
  }

  .pre-event-hero .coming-soon h3 {
    text-align: center;
  }

  .pre-event-hero .coming-soon .count-down {
    margin: 28px auto 0 auto;
  }

  .pre-event-hero .coming-soon .count-down th {
    font-size: 14px;
  }

  .pre-event-hero .coming-soon .count-down .attr {
    width: 85px;
    min-width: 80px;
    max-width: 80px;
  }

  .pre-event-hero .coming-soon .count-down tbody {
    font-size: 60px;
  }

  .pre-event-hero .coming-soon .count-down .value {
    width: 85px;
    min-width: 80px;
    max-width: 80px;
  }

  .pre-event-hero .coming-soon p {
    font-size: 16px;
  }


  .pre-event-hero .coming-soon a {
    font-size: 16px;
    height: 48px;
    width: 164px;
  }

  .pre-event-hero .coming-soon .register-btn {
    border-radius: 28px;
    border: 2px solid #ffd772;
    box-sizing: border-box;
    margin-right: 4px;
  }

  .pre-event-hero .coming-soon .learn-more-btn {
    margin-left: 4px;
    text-align: center;
  }
}


/* ===== Tablets ====== */
/* Width: 834px  */
@media (max-width: 834px) {
  .pre-event-hero {
    max-height: 720px;
  }

  .pre-event-hero .colored-bars {
    bottom: -10%;
    height: 95%;
    justify-items: bottom;
    position: absolute;
    right: -7%;
    z-index: 0;

    /* Changes */
    /* positioning*/
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -30%;

    /* box model */
    height: auto;
    max-height: 95%;
    width: 800px;
    max-width: 95%;

    /* typography */
    text-align: center;
  }

  .pre-event-hero .coming-soon {
    max-width: 80%;
    margin-top: 6vh;
  }

  .pre-event-hero .coming-soon h3 {
    font-size: 60px;
  }

  .pre-event-hero .coming-soon .count-down th {
    font-size: 13px;
  }

  .pre-event-hero .coming-soon .count-down .attr {
    min-width: auto;
    max-width: none;
    width: 20px;
  }

  .pre-event-hero .coming-soon .count-down tbody {
    font-size: 48px;
  }

  .pre-event-hero .coming-soon .count-down .value {
    min-width: auto;
    max-width: none;
    width: 70px;
    
  }

  .pre-event-hero .coming-soon p {
    font-size: 16px;
  }

  .pre-event-hero .coming-soon a {
    font-size: 16px;
    height: 48px;
    width: 164px;
  }
}


/* ===== Large Mobile ====== */
/* Width: 530px  */
@media (max-width: 530px) {
  .pre-event-hero {
    /* Prev Changes */
    max-height: 900px;

    /* Changes */
    height: 95vh;
    max-height: 720px;
  }

  .pre-event-hero .colored-bars {
    /* Changes */
    /* positioning*/
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -30%;

    /* box model */
    height: auto;
    max-height: 80%;
    width: 800px;
    max-width: 95%;

    /* typography */
    text-align: center;
  }

  .pre-event-hero .colored-bars img {
    bottom: 0;
    width: 100%;
  }

  .pre-event-hero .coming-soon {
    /* Prev Changes */
    text-align: center;
    top: 30%;
    right: 0;
    left: 0;
    margin: 0 auto;

    /* Changes */
    max-width: 85%;
    margin-top: 12%;
  }

  .pre-event-hero .coming-soon h3 {
    font-size: 54px;
  }

  .pre-event-hero .coming-soon .count-down th {
    font-size: 12px;
  }

  .pre-event-hero .coming-soon .count-down .attr {
    min-width: auto;
    max-width: none;
    width: 20px;
  }

  .pre-event-hero .coming-soon .count-down tbody {
    font-size: 40px;
  }

  .pre-event-hero .coming-soon .count-down .value {
    min-width: auto;
    max-width: none;
    width: 70px;
  }

  .pre-event-hero .coming-soon p {
    font-size: 14px;
  }

  .pre-event-hero .coming-soon .register-btn {
    border-radius: 28px;
    border: 2px solid #ffd772;
    box-sizing: border-box;
    margin-right: 0;
  }



  .pre-event-hero .coming-soon .learn-more-btn {
    margin-left: 0;
  }
}


/* ===== Mobile ====== */
/* Width: 414px  */
@media (max-width: 414px) {
  .pre-event-hero {
    height: 100vh;
    max-height: 720px;
  }

  .pre-event-hero .coming-soon {
    max-width: 85%;
    margin-top: 6vh;
  }

  .pre-event-hero .coming-soon h3 {
    font-size: 36px;
  }

  .pre-event-hero .coming-soon .count-down th {
    font-size: 11px;
  }

  .pre-event-hero .coming-soon .count-down .attr {
    width: 50px;
  }

  .pre-event-hero .coming-soon .count-down tbody {
    font-size: 30px;
  }

  .pre-event-hero .coming-soon .count-down .value {
    width: 50px;
  }

  .pre-event-hero .coming-soon p {
    font-size: 11px;
  }

  .pre-event-hero .coming-soon .btn-container {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-top: 36px;
  }

  .pre-event-hero .coming-soon a {
     font-size: 12px;
     height: 40px;
     width: 112px;
  }
}
