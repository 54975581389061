.about-us {
  box-sizing: border-box;

  font-family: "Google Sans", "Open Sans";

  /* background: url("../../../../assets/images/png/About-Us-bg.png"); */
  background-repeat: repeat;
  background-size: 100%;
  background-position: center;
}

.aboutUsDesc {
  justify-content: center;
  align-items: center;
  
}

.aboutUsText {
  font-size: 47px;
  line-height: 61px;
  display: block;
  text-align: center;
  margin-top: 10%;
  font-weight: 700;
}

.orgText {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  margin: 40px auto 0 auto;
  width: 1200px;
  max-width: 80%;
}

.dscPlmLogo {
  height: 232px;
}


/* Core team names */


.personName {
  font-size: 19px;
  font-weight: 700;
}

.orgText p {
  font-size: 18px;
  line-height: 23px;
  margin-left: 40px;
}

/* Core team Images */

.image {
  height: auto;
  width: 170px;
  border-radius: 50%;
}

.coreTeamText {
  font-size: 47px;
  line-height: 61px;
  display: block;
  text-align: center;
  margin: 10px;
  font-weight: 700;
  margin-top: 90px;
}

.peopleCards {
  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr)); */
  display: flex;
  flex-wrap: wrap;
  grid-auto-rows: auto;
  align-items: center;
  justify-content: center;
  text-align: center;
  grid-gap: 90px 0px;
  margin: 80px auto;
  padding-top: 10px;
  padding-bottom: 40px;
  max-width: 80%;
}

.indivCard {
  width: 240px;
}

.btnDiv {
  margin-top: 40px;
}

.btnProfile {
  font-weight: normal;
  font-size: 14px;
  border-style: solid;
  border-color: rgb(219, 221, 225);
  border-width: 1px;
  border-radius: 4px;
  padding: 15px;
  cursor: pointer;
  color: rgb(82, 150, 246);
  text-decoration: none !important;
}

.btnProfile:hover {
  background-color: #f5fcff;
}


@media (max-width: 1280px) {
  .orgText p {
    font-size: 16px;
  }
}





@media screen and (max-width: 800px) {
  .about-us {
    /* background: url("../../../../assets/images/png/About-Us-bg.png"); */
    background-repeat: repeat;
    background-size: contain;
    background-position: center;
    padding-top: 130px;
  }

  .aboutUsDesc {
    margin-bottom: 60px;
  }

  .orgText {
    display: block;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
  }

  .dscPlmLogo {
    margin: 0 auto;
    text-align: center;
    display: block;
  }

  .orgText p {
    font-size: 15px;
    line-height: 23px;
    margin: 40px 0px;
  }

  .coreTeamText {
    font-size: 47px;
    line-height: 61px;
    display: block;
    text-align: center;
    margin: 5px;
    font-weight: 700;
    margin-top: 90px;
    margin-bottom: 40px;
  }

  
  /* Core team names */
  
  .personName {
    font-size: 19px;
    font-weight: 700;
  }
  
  
  /* Core team Images */
  
  .image {
    height: auto;
    width: 170px;
    border-radius: 50%;
  }

  .personPosition {
    font-size: 14px;
  }

  /* .peopleCards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-auto-rows: auto;
    align-items: center;
    justify-content: center;
    text-align: center;
    grid-gap: 90px 0px;
    margin: 150px 100px 150px 100px;
    padding-top: 10px;
    padding-bottom: 40px;
  } */
}





@media screen and (max-width: 550px) {
  .about-us {
    /* background: url("../../../../assets/images/png/About-Us-bg.png"); */
    background-repeat: repeat;
    background-size: contain;
    background-position: center;
    padding-top: 100px;
  }

  .aboutUsText {
    font-size: 38px;
    line-height: 61px;
    display: block;
    text-align: center;
    margin: 10px;
    font-weight: 700;
  }

  .orgText p {
    font-size: 11px;
    line-height: 23px;
    margin: 70px 0px;
  }
  
  .coreTeamText {
    font-size: 38px;
    line-height: 61px;
    display: block;
    text-align: center;
    margin: 10px 30px;
    font-weight: 700;
    margin-top: 90px;
  }

  .orgText {
    display: block;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
  }

  .dscPlmLogo {
    margin: 0 auto;
    text-align: center;
    display: block;
  }

  .orgText p {
    font-size: 13px;
  }

  /* .peopleCards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-auto-rows: auto;
    align-items: center;
    justify-content: center;
    text-align: center;
    grid-gap: 70px 0px;
    margin: 50px 100px 50px 100px;
    padding-top: 10px;
    padding-bottom: 40px;
  } */

  .image {
    height: auto;
    width: 120px;
    border-radius: 50%;
  }

   /* Core team names */
  
   .personName {
    font-size: 15px;
    font-weight: 700;
  }

  .personPosition {
    font-size: 13px;
  }

  .btnDiv {
    margin-top: 40px;
  }
  
  .btnProfile {
    font-weight: normal;
    font-size: 12px;
    border-style: solid;
    border-color: rgb(219, 221, 225);
    border-width: 1px;
    border-radius: 4px;
    padding: 13px;
    cursor: pointer;
    color: rgb(82, 150, 246);
    text-decoration: none !important;
  }
  
  .btnProfile:hover {
    background-color: #f5fcff;
  }
}



@media screen and (max-width: 400px) {
  .about-us {
    /* background: url("../../../../assets/images/png/About-Us-bg.png"); */
    background-repeat: repeat;
    background-size: contain;
    background-position: center;
    padding-top: 120px;
  }

  .aboutUsText {
    font-size: 28px;
    line-height: 61px;
    display: block;
    text-align: center;
    margin: 10px;
    font-weight: 700;
  }

  .coreTeamText {
    font-size: 28px;
    line-height: 61px;
    display: block;
    text-align: center;
    margin: 10px 30px;
    font-weight: 700;
    margin-top: 90px;
  }

  .orgText {
    display: block;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    padding: 0px 40px 0px 40px;
  }

  .dscPlmLogo {
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }

  .orgText p {
    font-size: 12px;
    line-height: 23px;
    margin: 70px 0px;
  }

  /* .peopleCards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-auto-rows: auto;
    align-items: center;
    justify-content: center;
    text-align: center;
    grid-gap: 70px 0px;
    margin: 50px 100px 50px 100px;
    padding-top: 10px;
    padding-bottom: 40px;
  } */

  .image {
    height: auto;
    width: 120px;
    border-radius: 50%;
  }

   /* Core team names */
  
   .personName {
    font-size: 15px;
    font-weight: 700;
  }

  .personPosition {
    font-size: 13px;
  }

  .btnDiv {
    margin-top: 40px;
  }
  
  .btnProfile {
    font-weight: normal;
    font-size: 12px;
    border-style: solid;
    border-color: rgb(219, 221, 225);
    border-width: 1px;
    border-radius: 4px;
    padding: 12px;
    cursor: pointer;
    color: rgb(82, 150, 246);
    text-decoration: none !important;
  }
  
  .btnProfile:hover {
    background-color: #f5fcff;
  }
}
