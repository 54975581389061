.add-testing-border {
  border-style: solid;
  border-width: 1px;
  border-color: rgb(0, 0, 0);
}

.home-page {
  padding-top: 80px;
  height: 100%;
}

@media (max-width: 820px) {
  .home-page {
    padding-top: 60px;
    height: 100%;
  }
}

@media (max-width: 820px) {
    .home-page {
      padding-top: 60px;
    }
}

@media (max-width: 540px) {
    .home-page {
        padding-top: 0px;
      }

}